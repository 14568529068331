<template>
  <div class="admin dashboard">

    <header class="admin-header cf">
      <h1>{{ $t('admin.waiting_lines') }}</h1>
      <div class="meta">
        <a @click="modal_add = true" class="button button-red">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus"
            role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor"
              d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z">
            </path>
          </svg>
          <span>{{ $t('admin._waiting_lines.add_waiting_line') }}</span>
        </a>
      </div>
    </header>

    <div v-if="loading" class="loading-wrap">
      <span class="loading-spinner"></span>
    </div>
    <div v-else>

      <div class="search-wrap">
        <input v-model="search_phrase" v-on:input="search_handler" type="text" class="form-search">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
      </div>

      <div v-if="search_loading" class="loading-wrap">
        <span class="loading-spinner"></span>
      </div>
      <div v-else>
        <div v-if="waiting_lines.length == 0" class="alert alert-info">
          <p>{{ $t('admin._waiting_lines.no_waiting_lines') }}.</p>
        </div>
        <div v-else>
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t('admin._waiting_lines.name') }}</th>
                <th>{{ $t('admin._waiting_lines.counter') }}</th>
                <th>{{ $t('admin._waiting_lines.status') }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="waiting_line in waiting_lines" v-bind:key="waiting_line.id">
                <td width="30%">
                  {{ waiting_line.name }}
                </td>
                <td width="35%">
                  <span v-for="name in waiting_line.counter_names" v-bind:key="name"
                    style="margin: 5px 10px 5px 0; display:inline-block;background: #F6F6F6;border: 1px solid #D0D0D0;border-radius: 4px;font-size:12px;padding:5px 8px">{{
                      name
                    }}</span>
                </td>
                <td width="20%">
                  <span v-if="waiting_line.status == 'disabled'" class="status-hidden">
                    <span class="status-icon"></span>
                    <span class="status-text">Inaktiv</span>
                  </span>
                  <span v-if="waiting_line.status == 'enabled'" class="status-visible">
                    <span class="status-icon"></span>
                    <span class="status-text">Aktiv</span>
                  </span>
                </td>
                <td width="15%" class="actions">
                  <router-link :to="'/admin/waiting_lines/' + waiting_line.id">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </router-link>
                  <router-link :to="'/waiting_lines/' + waiting_line.id">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="link"
                      class="svg-inline--fa fa-link" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                      <path fill="currentColor"
                        d="M598.6 41.41C570.1 13.8 534.8 0 498.6 0s-72.36 13.8-99.96 41.41l-43.36 43.36c15.11 8.012 29.47 17.58 41.91 30.02c3.146 3.146 5.898 6.518 8.742 9.838l37.96-37.96C458.5 72.05 477.1 64 498.6 64c20.67 0 40.1 8.047 54.71 22.66c14.61 14.61 22.66 34.04 22.66 54.71s-8.049 40.1-22.66 54.71l-133.3 133.3C405.5 343.1 386 352 365.4 352s-40.1-8.048-54.71-22.66C296 314.7 287.1 295.3 287.1 274.6s8.047-40.1 22.66-54.71L314.2 216.4C312.1 212.5 309.9 208.5 306.7 205.3C298.1 196.7 286.8 192 274.6 192c-11.93 0-23.1 4.664-31.61 12.97c-30.71 53.96-23.63 123.6 22.39 169.6C293 402.2 329.2 416 365.4 416c36.18 0 72.36-13.8 99.96-41.41L598.6 241.3c28.45-28.45 42.24-66.01 41.37-103.3C639.1 102.1 625.4 68.16 598.6 41.41zM234 387.4L196.1 425.3C181.5 439.1 162 448 141.4 448c-20.67 0-40.1-8.047-54.71-22.66c-14.61-14.61-22.66-34.04-22.66-54.71s8.049-40.1 22.66-54.71l133.3-133.3C234.5 168 253.1 160 274.6 160s40.1 8.048 54.71 22.66c14.62 14.61 22.66 34.04 22.66 54.71s-8.047 40.1-22.66 54.71L325.8 295.6c2.094 3.939 4.219 7.895 7.465 11.15C341.9 315.3 353.3 320 365.4 320c11.93 0 23.1-4.664 31.61-12.97c30.71-53.96 23.63-123.6-22.39-169.6C346.1 109.8 310.8 96 274.6 96C238.4 96 202.3 109.8 174.7 137.4L41.41 270.7c-27.6 27.6-41.41 63.78-41.41 99.96c-.0001 36.18 13.8 72.36 41.41 99.97C69.01 498.2 105.2 512 141.4 512c36.18 0 72.36-13.8 99.96-41.41l43.36-43.36c-15.11-8.012-29.47-17.58-41.91-30.02C239.6 394.1 236.9 390.7 234 387.4z">
                      </path>
                    </svg>
                  </router-link>
                  <a @click="edit_waiting_line(waiting_line)">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>
                  </a>
                  <!--<a @click="delete_waiting_line(waiting_line)">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </a>-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_add">
      <svg @click="modal_add = false" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle"
        class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512">
        <path fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z">
        </path>
      </svg>
      <div class="modal-head">
        <h3>{{ $t('admin._waiting_lines.add_waiting_line') }}</h3>
      </div>
      <div class="modal-body">

        <div class="form-wrap select-wrap">
          <label for="status">Status</label>
          <select v-model="waiting_line.status" class="form-input">
            <option key="hidden" value="disabled">Inaktiv</option>
            <option key="visible" value="enabled">Aktiv</option>
          </select>
        </div>

        <div class="form-wrap">
          <label for="name" class="form-label">Name</label>
          <input v-model="waiting_line.name" class="form-input input-grey" type="text" name="name" id="name">
        </div>

        <div class="form-wrap">
          <label for="name" class="form-label">Schalter</label>
          <select v-model="selected_waiting_line.counters" multiple class="form-input">
            <option v-for="counter in counters" v-bind:key="counter.id" v-bind:value="counter.id">{{ counter.name }}
            </option>
          </select>
        </div>

      </div>
      <div class="modal-meta">
        <a @click="create_waiting_line" class="button button-red button-100">{{
          $t('admin._waiting_lines.create_waiting_line') }} ›</a>
      </div>
    </vue-final-modal>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_edit">
      <svg @click="modal_edit = false" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle"
        class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512">
        <path fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z">
        </path>
      </svg>
      <div class="modal-head">
        <h3>{{ $t('admin._waiting_lines.edit_waiting_line') }}</h3>
      </div>
      <div class="modal-body">

        <div class="form-wrap select-wrap">
          <label for="status">Status</label>
          <select v-model="selected_waiting_line.status" class="form-input">
            <option key="hidden" value="disabled">Inaktiv</option>
            <option key="visible" value="enabled">Aktiv</option>
          </select>
        </div>

        <div class="form-wrap">
          <label for="name" class="form-label">Name</label>
          <input v-model="selected_waiting_line.name" class="form-input input-grey" type="text" name="name" id="name">
        </div>

        <div class="form-wrap">
          <label for="name" class="form-label">Schalter</label>
          <select v-model="selected_waiting_line.counters" multiple class="form-input">
            <option v-for="counter in counters" v-bind:key="counter.id" v-bind:value="counter.id">{{ counter.name }}
            </option>
          </select>
        </div>

        <div class="form-wrap" style="margin: 20px 0 10px 0">
          <input v-model="selected_waiting_line.show_away_screen" type="checkbox" id="show_away_screen"
            style="display:inline-block;vertical-align:middle;margin-right:5px">
          <label for="show_away_screen" style="display:inline-block;vertical-align:middle;margin:0">Soll außerhalb der
            Öffnungszeiten eine Info erscheinen?</label>
        </div>

        <div v-if="selected_waiting_line.show_away_screen == true"
          style="border: 1px dashed #f1f1f1;padding:20px;border-radius:10px">
          <div class="form-wrap">
            <label for="away_screen_head" class="form-label">Überschrift</label>
            <input v-model="selected_waiting_line.away_screen_head" class="form-input input-grey" type="text"
              name="away_screen_head" id="away_screen_head">
          </div>

          <div class="form-wrap">
            <label for="away_screen_body" class="form-label">Hinweis</label>
            <input v-model="selected_waiting_line.away_screen_body" class="form-input input-grey" type="text"
              name="away_screen_body" id="away_screen_body">
          </div>

          <div class="form-wrap" style="margin-bottom:0">
            <label for="name" class="form-label">Standort nur für Öffnungszeiten</label>
            <select v-model="selected_waiting_line.location_id" class="form-input">
              <option key="" value=""></option>
              <option v-for="location in locations" v-bind:key="location.id" v-bind:value="location.id">{{ location.name
              }}</option>
            </select>
          </div>
        </div>

      </div>
      <div class="modal-meta">
        <a @click="update_waiting_line" class="button button-red button-100">{{
          $t('admin._waiting_lines.edit_waiting_line') }} ›</a>
      </div>
    </vue-final-modal>

  </div>
</template>

<script>
export default {
  name: 'admin_waiting_lines',
  data() {
    return {
      loading: true,
      modal_add: false,
      modal_edit: false,
      waiting_lines: [],
      counters: [],
      locations: [],
      waiting_line: {},
      selected_waiting_line: {},
      search_loading: false,
      search_phrase: "",
      searching: false,
      user_id: null,
      user: {},
      user_location: null,
      class_location: null
    }
  },
  methods: {
    async get_data() {
      await this.$http.get(process.env.VUE_APP_BASE_API + '/user', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.user_id = response.data.user.id;
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/users/' + this.user_id, { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.user = response.data.user;
          if (this.user.locations && this.user.locations.length > 0) {
            this.user_location = this.user.locations[0].id;
          }
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/locations', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.locations = response.data.locations;
        })
      if (this.user_location) {
        this.class_location = this.user_location
      }
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/waiting_lines', { params: { location_id: this.class_location }, headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.waiting_lines = response.data.waiting_lines;
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/counters', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.counters = response.data.counters;
        })
      this.loading = false;
    },
    delete_waiting_line(waiting_line) {
      if (confirm("Wirklich löschen?")) {
        this.$http.delete(process.env.VUE_APP_BASE_API + '/admin/waiting_lines/' + waiting_line.id, { headers: { Authorization: this.$store.getters.get_token } })
          .then(() => {
            this.get_data();
            this.$notify({
              title: "Erfolgreich gelöscht.",
              type: "success"
            });
          })
      }
    },
    edit_waiting_line(waiting_line) {
      this.selected_waiting_line = waiting_line;
      this.modal_edit = true;
    },
    create_waiting_line() {
      this.$http.post(process.env.VUE_APP_BASE_API + '/admin/waiting_lines', this.waiting_line, { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.modal_add = false;
          this.service = {};
          this.$notify({
            title: "Erfolgreich erstellt.",
            type: "success"
          });
        })
        .catch(error => {
          error.response.data.errors.forEach(value => {
            this.$notify({
              title: value,
              type: "error"
            });
          });
        });
    },
    update_waiting_line() {
      this.$http.patch(process.env.VUE_APP_BASE_API + '/admin/waiting_lines/' + this.selected_waiting_line.id, this.selected_waiting_line, { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.modal_edit = false;
          this.$notify({
            title: "Erfolgreich gespeichert.",
            type: "success"
          });
        })
        .catch(error => {
          error.response.data.errors.forEach(value => {
            this.$notify({
              title: value,
              type: "error"
            });
          });
        });
    },
    search_handler() {
      if (this.searching == false) {
        this.search_loading = true;
        this.searching = true;
        this.$http.get(process.env.VUE_APP_BASE_API + '/admin/waiting_lines', {
          params: {
            search_phrase: this.search_phrase
          },
          headers: { Authorization: this.$store.getters.get_token }
        })
          .then(response => {
            this.waiting_lines = response.data.waiting_lines;
            this.search_loading = false;
            this.searching = false;
          })
      }
    },
  },
  mounted() {
    this.get_data();
  }
}
</script>
